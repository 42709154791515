<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="DO Replacement" sub-title="Changing the content of an Old DO into a New DO">
      <b-modal
        id="promptError"
        ref="modal"
        v-model="promptError"
        title="Error Comparison List"
        hide-footer
      >
        <b-table :fields="fields" :items="comparisonFailed" show-empty></b-table>
      </b-modal>
      <b-modal
        id="promptSuccess"
        ref="modal"
        v-model="promptSuccess"
        title="Item Warranty Registered"
        hide-footer
      >
        <b-table :fields="fieldsSuccess" :items="comparisonSucceed" show-empty></b-table>
      </b-modal>
      <b-col cols="12">
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <br>
        <b-row class="hide-on-mobile">
          <b-col md="6"> 
            <label for="search" class="form-label">Please input original old DO number that want to replace</label>
            <b-form-input
              v-model="oldDo"
              type="search"
              placeholder="Input old DO Number"
              style="float: right;"
              hide-footer
              header-class="modal-header-error"
            />
          </b-col>
        </b-row>
        <b-row class="hide-on-desktop">
          <b-col md="6"> 
            <label for="search" class="form-label">Please input original old DO number that want to replace</label>
            <b-form-input
              v-model="oldDo"
              type="search"
              placeholder="Input old DO Number"
              style="float: right;"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6"> 
            <label for="search" class="form-label">Please input new replacement DO number</label>
            <b-form-input
              v-model="newDo"
              type="search"
              placeholder="Input new DO Number"
              style="float: right;"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <b-button style="margin-top: 22px;" @click="doFiltering" variant="success">Replace DO</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>
  
  <script>
  import { mapActions } from "vuex";
  import axios from "@/axios";
  import { userAccess, dateFormat, sleep } from "@/utils/utils";
  
  export default {
    data() {
      return {
        search : "",
        isLoading : false,
        canEdit : false,
        selectStatus : "",
        status : [],
        buttonSubmit : false,
        doData : {},
        newDo : "",
        oldDo : "",
        comparisonFailed : [],
        fields: [
          { key: 'error', label: 'Failed Comparison list' },
        ],
        promptError : false,
        promptSuccess : false,
        comparisonSucceed : [],
        fieldsSuccess : [
          { key: 'itemModel', label: 'Model' },
          { key: 'barcode', label: 'Barcode' },
        ],
      }
    },
    computed: {
    },
    created() {
      document.title = "Replace DO | RSP";
    },
    mounted() {
    },
    watch: {
    },
    methods: {
      ...mapActions({
        replaceDo:"delivery/replaceDo"
      }),
      async doFiltering() {
        this.isLoading = true;
        await this.replaceDo({ oldSapDo: this.oldDo, newSapDo: this.newDo })
          .then((data) => {
            this.isLoading = false;
            this.$bvToast.toast("Replace DO Success", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.comparisonSucceed = data.registeredItems
            this.promptSuccess = true;
            this.oldDo = ""
            this.newDo = ""
          })
          .catch((error) => {
            this.isLoading = false;
            let errorMessage;
            if (error.response.data.errors) {
              errorMessage = error.response.data.errors[0].message;
            } else if (error.response.data.error) {
              if (Array.isArray(error.response.data.error)) {
                errorMessage = "Comparison errors detected";
                this.comparisonFailed = error.response.data.error.map(err => ({ error: err }));
                this.promptError = true;
              } else {
                errorMessage = error.response.data.error;
              }
            }
            this.$bvToast.toast(errorMessage, {
              title: "Fail",
              variant: "danger",
              solid: true,
            });
          });
      }
    },
  };
  </script>
  <style scope>
  .hide-at-all {
    display: none !important;
  }
  
  /* For mobile phones: */
  @media (max-width: 761px) {
    .hide-on-mobile {
      display: none !important;
    }
  }
  
  /* For computer: */
  @media (min-width: 761px) {
    .hide-on-desktop {
      display: none !important;
    }
    .show-on-desktop {
      display: inline !important;
    }
  }
  </style>
  